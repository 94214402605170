import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  PageContent_acfSharedPage,
  PageContent_acfSharedPage_globalPageSettings_jsonLd_product_properties as ProductProperty,
} from 'queries/gql_types/PageContent';

type Breadcrumb = {
  text: string;
  url: string;
};

type FAQ = {
  question: string;
  answer: string;
};

type ProductAggregateRating = {
  '@type': 'AggregateRating';
  ratingCount?: string;
  reviewCount?: string;
  ratingValue: string;
};

type Product = {
  name: string;
  brandName?: string;
  description?: string;
  aggregateRating: ProductAggregateRating | undefined;
};

const isAbsolutePath = (url: string) =>
  url.indexOf('http://') === 0 || url.indexOf('https://') === 0;

const createJsonLDBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name: breadcrumb.text,
      item: isAbsolutePath(breadcrumb.url)
        ? breadcrumb.url
        : `https://www.pavilioncosmetic.com.au${breadcrumb.url}`,
    })),
  };
};

const createJsonLDFAQs = (faqs: FAQ[]) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: faqs.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  };
};

const createJsonLDProduct = ({ name, description, brandName, aggregateRating }: Product) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    ...(brandName
      ? {
          brand: {
            '@type': 'Brand',
            name: brandName,
          },
        }
      : undefined),
    ...(description ? { description } : undefined),
    ...(aggregateRating ? { aggregateRating } : undefined),
  };
};

const createAggregateRating = (aggregateRating: {
  ratingValue: string | null;
  ratingCount: string | null;
  reviewCount: string | null;
}) => {
  if (!aggregateRating.ratingValue) {
    return undefined;
  }
  return {
    '@type': 'AggregateRating' as const,
    ratingValue: aggregateRating.ratingValue,
    ...(aggregateRating.ratingCount ? { ratingCount: aggregateRating.ratingCount } : undefined),
    ...(aggregateRating.reviewCount ? { reviewCount: aggregateRating.reviewCount } : undefined),
  };
};

export const JsonLD = ({
  acfSharedPage,
  title,
  breadcrumbs,
  faqs,
}: {
  acfSharedPage: PageContent_acfSharedPage | null;
  title: string; // PageTitle
  breadcrumbs: Breadcrumb[];
  faqs?: FAQ[];
}) => {
  const enableBreadcrumbs = acfSharedPage?.globalPageSettings?.jsonLd?.breadcrumbs
    ?.enableBreadcrumbs
    ? true
    : false;

  const enableFaq = acfSharedPage?.globalPageSettings?.jsonLd?.faqs?.enableFaq ? true : false;

  const enableProduct = acfSharedPage?.globalPageSettings?.jsonLd?.product?.enableProduct
    ? true
    : false;

  if (!enableBreadcrumbs && !enableFaq && !enableProduct) {
    return null;
  }

  const product = acfSharedPage?.globalPageSettings?.jsonLd?.product ?? undefined;
  const aggregateRating = product?.properties?.find(
    (property): property is ProductProperty =>
      property?.__typename ===
      'WpPage_Acfsharedpage_GlobalPageSettings_JsonLd_Product_Properties_AggregateRating',
  );

  const structuredData = [
    enableBreadcrumbs && createJsonLDBreadcrumbs(breadcrumbs),
    enableFaq && faqs && createJsonLDFAQs(faqs),
    enableProduct && aggregateRating
      ? createJsonLDProduct({
          description: product?.description ?? undefined,
          brandName: product?.brandName ?? undefined,
          name: title,
          aggregateRating: createAggregateRating(aggregateRating),
        })
      : undefined,
  ].filter(Boolean);

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
    </Helmet>
  );
};
