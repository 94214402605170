import * as React from 'react';
import classNames from 'classnames';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { Placeholder, AllowedPlaceholderTypes } from 'ui/components/atoms/placeholder/placeholder';
import styles from './picture.module.css';

type AllowedPictureTypes =
  | { type: 'fluid'; fluid: FluidObject | undefined; placeholder: AllowedPlaceholderTypes }
  | { type: 'fixed'; fixed: FixedObject | undefined; placeholder: AllowedPlaceholderTypes };

type PictureProps = AllowedPictureTypes & {
  className?: string;
};

const Loading = ({ aspectRatio, className }: { aspectRatio: number; className?: string }) => (
  <div className={classNames(styles.loading, className)}>
    <div className={styles.pulse} style={{ paddingBottom: `${aspectRatio * 100}%` }}></div>
  </div>
);
const PictureComponent = (props: PictureProps) => {
  switch (props.type) {
    case 'fixed':
      return props.fixed ? (
        <>
          <Loading
            aspectRatio={props.fixed.width / props.fixed.height}
            className={props.className}
          />
          <Img fixed={props.fixed} className={props.className} />
        </>
      ) : (
        <Placeholder type={props.placeholder} />
      );
    case 'fluid':
      return props.fluid ? (
        <>
          <Loading aspectRatio={1 / props.fluid.aspectRatio} className={props.className} />
          <Img fluid={props.fluid} className={props.className} />
        </>
      ) : (
        <Placeholder type={props.placeholder} />
      );
    default:
      throw Error();
  }
};

export const Picture = React.memo(PictureComponent);
