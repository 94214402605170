import * as React from 'react';
import imagePlaceholder285x190 from './placeholder-285x190.png';
import imagePlaceholder86x86 from './placeholder-86x86.png';
import styles from './placeholder.module.css';

export type AllowedPlaceholderTypes = '86x86' | '285x190';

type Placeholder = {
  width: number;
  height: number;
  imgSrc: string;
};

function createPlaceholder(type: AllowedPlaceholderTypes): Placeholder {
  switch (type) {
    case '86x86':
      return {
        width: 86,
        height: 86,
        imgSrc: imagePlaceholder86x86,
      };
    case '285x190':
      return {
        width: 285,
        height: 190,
        imgSrc: imagePlaceholder285x190,
      };
    default:
      throw new Error();
  }
}

export const Placeholder = ({ type }: { type: AllowedPlaceholderTypes }) => {
  const placeholder = createPlaceholder(type);
  return (
    <div
      className={styles.placeholder}
      style={{ paddingBottom: `${(placeholder.height / placeholder.width) * 100}%` }}>
      <img src={placeholder.imgSrc} className={styles.placeholderImg} />
    </div>
  );
};
